<template>
  <v-container>
    <v-row 
      class="mx-auto mb-10"
      v-for="categoria in categoriasArray"
      :key="categoria.nome"
    >
      <template v-if="getRelatoriosByCategoria(categoria.nome).length">
        <v-col cols="12">
          <h2 id="categoria-relatorios">
            {{ categoria.nome }}
          </h2>
        </v-col>
        <v-col
          xs="12" sm="6" md="4" xl="3" 
          v-for="(relatorio, _key) in getRelatoriosByCategoria(categoria.nome)"
          :key="_key"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  :class="!relatorio.ativo ? 'disabled-card' : ''"
                  :style="
                    hover && !relatorio.ativo
                    ? 'cursor: not-allowed'
                    : ''
                  "
                  id="caixa-relatorio"
                  class="mx-auto my-auto px-2 d-flex"
                  width="352px"
                  height="100px"
                  :color="
                    hover && relatorio.ativo
                    ? 'grey lighten-2'
                    : 'grey lighten-3'
                  "
                  elevation="1"
                  :to="!relatorio.ativo ? '' : `/relatorios/${relatorio.slug}/historico`"
                  v-bind="attrs"
                  v-on="on"
                >
                  <hint 
                    v-if="!relatorio.ativo"
                    msgModal="Este relatório está inativo. Para mais informações, entre em contato com o administrador do sistema."
                  />
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="2">
                      <v-icon
                        class="my-auto d-flex justify-center"
                        :size="40"
                        color="primary"
                      >
                        mdi-microsoft-excel
                      </v-icon>
                    </v-col>
                    <v-col cols="10">
                      <v-card-text class="mx-auto my-auto">
                        <div class="subtitle-2" style="word-break: normal">
                          {{ relatorio.nome | truncate(45)}}
                        </div>
                        <div class="caption text--secondary">
                          {{relatorio.descricao ? relatorio.descricao : 'descrição-temporaria'}}
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
            </template>
            {{relatorio.nome}}
          </v-tooltip>
        </v-col>
      </template>
    </v-row>
    <v-snackbar
      :value="true"
      :timeout="0" 
      color="#eeeeee"
      elevation="24"
      :class="getClassSnackBar"
    >
      <v-row class="ma-0">
        <v-col cols="10" class="pa-0 fcol-snack-rel">
          <v-text-field
            v-model="search"
            clereable
            append-icon="mdi-magnify"
            class="pt-0 mt-0"
            label="Procurar"
            hide-details
            single-line
          />
        </v-col>
        <v-col cols="2" class="pa-0">
          <v-btn icon color="primary" @click="changeDirection()" style="margin-left: 10px">
            <v-icon size="25">{{snackManagerIcon}}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-container>
</template>

<script>

import AuthService from '@/services/AuthService';
import RelatoriosService from '@/services/RelatoriosService';
import tourMixins from '@/mixins/tourMixins';

export default {

  mixins: [
    tourMixins
  ],

  components: { 
    Hint: () => import('@/components/general/Hint'),
  },

  data: () => ({
    snackManagerIcon: 'mdi-chevron-double-right',
    search: '',
    categoriasArray: [],
    relatoriosArray: [],
    arrTour: [
      {
        element: '#categoria-relatorios',
        intro: 'Os relatórios são divididos em categorias.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#caixa-relatorio',
        intro: 'Cada caixa representa um tipo de relatório. Clique no tipo de relatório que você deseja para ter acesso ao histórico e criação de novos relatórios.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    tourFuncCalled: false,
    userIsAdmin: false
  }),

  created() {
    this.getCategorias();
  },

  methods: {

    getCategorias() {
      RelatoriosService
        .getCategorias()
        .then(async (responseData) => {
          this.userIsAdmin = await AuthService.userIsAdmin();
          this.categoriasArray = responseData.toSorted((a, b) => b.nome.localeCompare(a.nome));
          this.getRelatorios();
        });
    },

    getRelatorios() {
      RelatoriosService.index()
      .then(responseData => {
        this.relatoriosArray = responseData;
        this.iniciarTourPagina(); 
      })
    },

    getRelatoriosByCategoria(nomeCategoria) {
      return this.relatoriosArray.filter(relatorio => {
        return relatorio.categoria === nomeCategoria && relatorio.nome.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    changeDirection() {
      this.snackManagerIcon = this.snackManagerIcon == 'mdi-chevron-double-right' ? 'mdi-magnify' : 'mdi-chevron-double-right';
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;
      this.tourFuncCalled = true;
      this.iniciarTour(this.arrTour);
    }
  },

  computed: {
    getClassSnackBar() {
      return this.snackManagerIcon == 'mdi-chevron-double-right' ? 'hidden-search-rel' : 'show-search-rel';
    },
  },

}
</script>

<style>

.show-search-rel .v-snack__wrapper {
  transition: 0.5s;
  min-width: 50px !important;
}

.show-search-rel .fcol-snack-rel {
  display: none;
}

.show-search-rel button {
  margin-left: -9px !important;
}

.hidden-search-rel .v-snack__wrapper {
  transition: 0.5s;
  min-width: 300px !important;
}

.hidden-search-rel .fcol-snack-rel {
  display: block;
}

.disabled-card {
  opacity: 0.5;
  border-color: transparent !important;
}

</style>