var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_vm._l((_vm.categoriasArray),function(categoria){return _c('v-row',{key:categoria.nome,staticClass:"mx-auto mb-10"},[(_vm.getRelatoriosByCategoria(categoria.nome).length)?[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{attrs:{"id":"categoria-relatorios"}},[_vm._v(" "+_vm._s(categoria.nome)+" ")])]),_vm._l((_vm.getRelatoriosByCategoria(categoria.nome)),function(relatorio,_key){return _c('v-col',{key:_key,attrs:{"xs":"12","sm":"6","md":"4","xl":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mx-auto my-auto px-2 d-flex",class:!relatorio.ativo ? 'disabled-card' : '',style:(hover && !relatorio.ativo
                  ? 'cursor: not-allowed'
                  : ''),attrs:{"id":"caixa-relatorio","width":"352px","height":"100px","color":hover && relatorio.ativo
                  ? 'grey lighten-2'
                  : 'grey lighten-3',"elevation":"1","to":!relatorio.ativo ? '' : ("/relatorios/" + (relatorio.slug) + "/historico")}},'v-card',attrs,false),on),[(!relatorio.ativo)?_c('hint',{attrs:{"msgModal":"Este relatório está inativo. Para mais informações, entre em contato com o administrador do sistema."}}):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"my-auto d-flex justify-center",attrs:{"size":40,"color":"primary"}},[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-card-text',{staticClass:"mx-auto my-auto"},[_c('div',{staticClass:"subtitle-2",staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(relatorio.nome,45))+" ")]),_c('div',{staticClass:"caption text--secondary"},[_vm._v(" "+_vm._s(relatorio.descricao ? relatorio.descricao : 'descrição-temporaria')+" ")])])],1)],1)],1)]}}],null,true)})]}}],null,true)},[_vm._v(" "+_vm._s(relatorio.nome)+" ")])],1)})]:_vm._e()],2)}),_c('v-snackbar',{class:_vm.getClassSnackBar,attrs:{"value":true,"timeout":0,"color":"#eeeeee","elevation":"24"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0 fcol-snack-rel",attrs:{"cols":"10"}},[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"clereable":"","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.changeDirection()}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(_vm._s(_vm.snackManagerIcon))])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }